.firebaseui-container {
  margin: 1rem auto;
  max-width: 600px;
  box-shadow: none;
}
.firebaseui-card-content {
  padding: 0;
}
form>.firebaseui-card-content {
  padding: 0 24px;
}
.firebaseui-idp-button {
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}
